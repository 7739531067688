
import { lang } from '@/lang'
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import ShareCard from '@/views/DomainRegister/components/ShareCard.vue'

@Component({
  components: { ShareCard }
})
export default class DomainItemInfo extends Vue {
  @Prop({ type: Object, required: true })
  private item!: Common.Domain.BaseInfo

  @Ref() private shareCard!:ShareCard

  private get text () {
    return {
      getWeb3: lang('DOMAIN_STATUS_GET_WEB3PROFILE'),
      share: lang('DOMAIN_STATUS_SHARE_CARD')
    }
  }

  private get owned () {
    return !!this.item.isMine
  }

  private get isFavor () {
    return this.item.isFavor
  }

  private get unavilable () {
    return this.item.status === 'unavilable'
  }

  private get status () {
    return this.item.status
  }

  private get statusLabel () {
    if (this.status === 'owned') {
      return lang('DOMAIN_STATUS_OWNED')
    } else if (this.status === 'available') {
      return lang('DOMAIN_STATUS_AVAILABLE')
    } else {
      return lang('DOMAIN_STATUS_UNAVAILABLE')
    }
  }

  private showCardShare () {
    this.shareCard.open()
  }
}
