
import { Component, Vue, Ref, Prop } from 'vue-property-decorator'
import html2canvas from 'html2canvas'
import { wallet } from '@/utils/wallet'
import loading from '@/components/Loading/Loading.vue'

@Component({
  components: { loading }
})
export default class ShareCard extends Vue {
  @Ref() private shareCardImg!: HTMLSelectElement

  @Prop({ type: String, default: '' })
  private domain!: string

  private show = false

  private transition = true

  private bgReady = false

  private get walletAddress () {
    return wallet.accountId.substr(0, 5) + '...' + wallet.accountId.substr(38)
  }

  private get dynamicStyle () {
    // length width '.dot'
    // font size scale 2 time
    const length = this.domain.length
    let fontsize
    let bottom
    if (length <= 20) {
      fontsize = '80'
      bottom = '400px'
    } else if (length <= 24) {
      fontsize = '64'
      bottom = '368px'
    } else if (length <= 38) {
      fontsize = '80'
      bottom = '368px'
    } else {
      fontsize = '64'
      bottom = '345px'
    }

    return {
      fontsize,
      bottom
    }
  }

  private bgIndex = Math.round(Math.random() * 6)

  open () {
    this.show = true
    this.bgIndex = Math.round(Math.random() * 6)
    const bgurl = `/share_card_bg/0${this.bgIndex}.png`
    const img = new Image()
    img.src = bgurl
    img.onload = () => {
      this.bgReady = true
    }

    setTimeout(() => {
      this.transition = false
    })
  }

  close () {
    this.show = false
    this.transition = true
    this.bgReady = false
  }

  private async toCanvas () {
    return await html2canvas(this.shareCardImg, { scale: 3 })
  }

  private async handleDownlod () {
    const canvas = await this.toCanvas()
    const MIME_TYPE = 'image/png'
    const imgURL = canvas.toDataURL(MIME_TYPE)
    const dlLink = document.createElement('a')
    dlLink.download = 'Polkadot Name System'
    dlLink.href = imgURL
    dlLink.dataset.downloadurl = [MIME_TYPE, dlLink.download, dlLink.href].join(':')
    document.body.appendChild(dlLink)
    dlLink.click()
    document.body.removeChild(dlLink)
  }

  mounted ():void {
    console.log('this.bgIndex', this.bgIndex)
  }
}
